import React, { Children } from "react";
import { graphql } from "gatsby";
import "../../index.scss";
import get from "lodash/get";
import {
  Section,
  Container,
  Content,
  Heading,
  Columns,
} from "react-bulma-components";
import Layout from "../../components/layouts/main-layout/index.jsx";
import Band from "../../components/bits/band/index";
import { BodySection } from "../../modules/body";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

export default function EditionsSingleTemplate(props) {
  if (!props) {
    return null;
  }

  const { year, cover, body, title, bands, media } = get(
    props,
    "data.contentfulEdition"
  );
  const { logoNavigation } = get(props, "data");
  // const slug = get(page, 'slug');
  // const body = get(page, 'body.childMarkdownRemark.html');
  // const description = get(page, 'description.childMarkdownRemark.html');
  // const heroImage = get(page, 'heroImage');
  // const eventGroupsName = get(page, 'eventGroupsName');
  // const eventGroups = get(page, 'eventGroups');
  // const edicions = get(props, 'data.allContentfulEdition.nodes');

  return (
    <Layout
      heroImage={cover}
      title={year}
      description={title}
      logoNavigation={logoNavigation}
    >
      <BodySection body={body} />

      {media && (
        <Section>
          <Container>
            <Columns>
              {media.map((img) =>
                Children.toArray(
                  <Columns.Column tablet={{ size: 6 }}>
                    <GatsbyImage image={getImage(img)} size="is-1by1" />
                  </Columns.Column>
                )
              )}
            </Columns>
          </Container>
        </Section>
      )}

      <Section>
        <Container>
          {bands?.map((band) =>
            Children.toArray(
              <Band {...band} aside skipRelated key={band.slug} />
            )
          )}
        </Container>
      </Section>
    </Layout>
  );
}

export const pageQuery = graphql`
  query EditionBySlug($slug: Date!) {
    logoNavigation: file(extension: {}, name: { eq: "logo-navigation" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    contentfulEdition(year: { eq: $slug }) {
      id
      title
      year
      media {
        description
        gatsbyImageData(layout: FULL_WIDTH)
      }
      bands {
        ...BandData
      }
      cover {
        description
        gatsbyImageData(layout: FULL_WIDTH)
      }
      body {
        childMarkdownRemark {
          html
        }
      }
    }
  }
`;
